@font-face {
    font-family: "Impact"; /*Can be any text*/
    src: local("impact"), url("./fonts/impact.ttf") format("truetype");
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Impact";
}
.tesla-counter {
    grid-row: 1/2;
    grid-column: 1/2;
    height: 122px;
}
.ev-counter {
    grid-row: 2/3;
    grid-column: 1/2;
    height: 122px;
    margin-top: 30px;
}
body, html, #root{
    overflow: hidden;
}
.tesla-counter,
.ev-counter {
    text-align: right;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    & .counter-name {
        font-size: 25px;
        font-style: italic;
        color: white;
        padding-top: 20px;
        padding-right: 20px;
        flex: 1;
    }
    & .counter-value {
        font-size: 100px;
        font-weight: bold;
        font-style: italic;
        color: white;
        text-align: start;
        padding-left: 20px;
        letter-spacing: 8px;
        flex: 1;
    }
}
.transparent-black-gradient {
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.8493522408963585) 50%,
        rgba(0, 0, 0, 0.9) 100%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.8493522408963585) 50%,
        rgba(0, 0, 0, 0.9) 100%
    );
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.8493522408963585) 50%,
        rgba(0, 0, 0, 0.9) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    height: 122px;
    top: 0px;
    grid-row: 1/2;
    grid-column: 1/2;
}
.transparent-green-gradient {
    background: rgb(83, 179, 27);
    background: -moz-linear-gradient(
        90deg,
        rgba(83, 179, 27, 0) 0%,
        rgba(77, 182, 27, 0.8493522408963585) 50%,
        rgba(59, 148, 29, 0.9) 100%
    );
    background: -webkit-linear-gradient(
        90deg,
        rgba(83, 179, 27, 0) 0%,
        rgba(77, 182, 27, 0.8493522408963585) 50%,
        rgba(59, 148, 29, 0.9) 100%
    );
    background: linear-gradient(
        90deg,
        rgba(83, 179, 27, 0) 0%,
        rgba(77, 182, 27, 0.8493522408963585) 50%,
        rgba(59, 148, 29, 0.9) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#53b31b",endColorstr="#3b941d",GradientType=1);
    height: 122px;
    grid-row: 2/3;
    grid-column: 1/2;
    margin-top: 30px;
}

.big-text {
    font-size: 80px;
    font-weight: bold;
    font-style: italic;
    color: white;
    letter-spacing: 2px;
}
.small-text {
    font-size: 40px;
    font-weight: bold;
    font-style: italic;
    color: white;
    letter-spacing: 2px;
}

iframe {
    -webkit-box-shadow: 0px 28px 23px 4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 28px 23px 4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 28px 23px 4px rgba(0, 0, 0, 0.75);
}

@media only screen and (max-width: 1920px) {
    .big-text {
        font-size: 60px;
    }
    .small-text {
        font-size: 20px;
    }
}
@media only screen and (max-width: 1440px) {
    .big-text {
        font-size: 40px;
    }
    .small-text {
        font-size: 20px;
    }
    .info-container,
    .livestream-container {
        margin-left: 50px !important;
    }
}
@media only screen and (max-width: 1280px) {
    .counter-value {
        font-size: 80px !important;
        padding-left: 10px !important;
    }
    .counter-name {
        font-size: 20px !important;
    }
    .tesla-counter,
    .transparent-black-gradient,
    .ev-counter,
    .transparent-green-gradient {
        height: 100px !important;
    }
    .counters-container {
        grid-template-rows: 100px 100px !important;
    }
    .ev-counter,
    .transparent-green-gradient {
        margin-top: 15px !important;
    }
}
@media only screen and (max-width: 1024px) {
    .counter-value {
        font-size: 60px !important;
        padding-left: 10px !important;
    }
    .counter-name {
        font-size: 15px !important;
        padding-top: 10px !important;
        padding-right: 10px !important;
    }
    .tesla-counter,
    .transparent-black-gradient,
    .ev-counter,
    .transparent-green-gradient {
        height: 80px !important;
    }
    .counters-container {
        grid-template-rows: 80px 80px !important;
    }
    .ev-counter,
    .transparent-green-gradient {
        margin-top: 15px !important;
    }
    .big-text {
        font-size: 30px !important;
    }
    .small-text {
        font-size: 20px;
    }
}
@media only screen and (max-width: 768px) {
    .info-container {
        grid-row: 1/2 !important;
        grid-column: 1/3 !important;
        margin: 30px 20px 20px 20px !important;
        align-items: center !important;
        flex-direction: column;
        justify-content: center;
    }
    .livestream-container {
        grid-row: 2/3 !important;
        grid-column: 1/3 !important;
        margin: 0px 20px !important;
    }
    .counters-container {
        grid-row: 3/4 !important;
        grid-column: 1/3 !important;
        margin: 0px 20px !important;
        width: 95% !important;
        grid-template-columns: 1fr 1fr;
    }
    .container {
        grid-template-columns: 1fr !important;
        grid-template-rows: 1.2fr 2fr 1.2fr !important;
    }
    .counter-name {
        flex: 4 !important;
        text-align: end !important;
    }
    .counter-value {
        text-align: end !important;
        padding-right: 20px !important;
        flex: 1 !important;
        font-size: 50px !important;
    }
    .big-text {
        font-size: 50px !important;
    }
    .small-text {
        font-size: 18px !important;
        text-align: left !important;
        align-self: flex-start !important;
    }
    iframe {
        height: 95%;
    }
    .transparent-black-gradient, .tesla-counter{
        grid-row: 1/2;
        grid-column: 1/2;
    }
    .single-counter{
        grid-row: 1/2;
        grid-column: 1/3;
        width: 95% !important;
    }
    .transparent-green-gradient, .ev-counter{
        grid-row: 1/2;
        grid-column: 2/3;
        margin-top: 0px !important;
    }
    .transparent-green-gradient{
        background: -moz-linear-gradient(
        270deg,
        rgba(83, 179, 27, 0) 0%,
        rgba(77, 182, 27, 0.8493522408963585) 50%,
        rgba(59, 148, 29, 0.9) 100%
    ) !important;
    background: -webkit-linear-gradient(
        270deg,
        rgba(83, 179, 27, 0) 0%,
        rgba(77, 182, 27, 0.8493522408963585) 50%,
        rgba(59, 148, 29, 0.9) 100%
    ) !important;
    background: linear-gradient(
        270deg,
        rgba(83, 179, 27, 0) 0%,
        rgba(77, 182, 27, 0.8493522408963585) 50%,
        rgba(59, 148, 29, 0.9) 100%
    ) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3b941d",endColorstr="#53b31b",GradientType=1) ;
    }
    .ev-counter{
        display: flex;
    }
    .ev-counter .counter-value{
        order: 1 !important;
        padding-right: 0px !important;
    }
    .ev-counter .counter-name{
        order: 2 !important;
        text-align: start !important;
        padding-left: 20px !important;
    }
    .tesla-counter, .transparent-black-gradient, .ev-counter, .transparent-green-gradient {
        height: 70px !important;
    }
}

@media only screen and (max-width: 512px) {
    .info-container {
        justify-content: center !important;
        gap: 15px;;
    }
   
}
